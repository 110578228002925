import React from "react"
import { graphql} from "gatsby"

import SEO from "../components/seo"
import Img from "gatsby-image"

import AniLink from "gatsby-plugin-transition-link/AniLink"

import Slide from 'react-reveal/Slide'

const StaticFilesPage = ({ data }) => (
  <>
    <SEO title="Ressources graphiques Uxo" />
    <div className="flex flex-col m-auto bg-jaune">
<h1 className="text-center text-3xl py-8 font-bold">Ressources statiques pour Uxo</h1>
<AniLink
            cover
            direction="right"
            bg="#b4fdf4"
            to="/"
            className="text-center hover:text-rose">Retour à l'accueil</AniLink>
<Slide right cascade>
<div className="flex m-auto p-20">
<Img fixed={data.florent.childImageSharp.fixed} className="mx-4"/> 
<Img fixed={data.seb.childImageSharp.fixed} className="mx-4"/> 
<Img fixed={data.will.childImageSharp.fixed} className="mx-4"/> 
<Img fixed={data.logo.childImageSharp.fixed} className="mx-4"/> 
<Img fixed={data.logoSmall.childImageSharp.fixed} className="mx-4"/> 
</div>
</Slide>
</div>
</>
)

export default StaticFilesPage

export const query = graphql`
  query {
    florent:file(relativePath: { eq: "florent-tamet-uxo.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fixed {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }
    seb:file(relativePath: { eq: "sebastien-bossi-croci-uxo.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fixed {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }
    will:file(relativePath: { eq: "willy-clauzel-uxo.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fixed {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }
    logo:file(relativePath: { eq: "uxo-logo-small.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fixed {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }
    logoSmall:file(relativePath: { eq: "uxo-logo-small-2.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fixed {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }
  }
`